// material
import { Container, Stack } from '@mui/material';
// components
import StudiesList from '../components/StudiesList';
import GraphsList from '../components/GraphsList';
import EndpointsList from '../components/EndpointsList';
import StudiesFilter from '../components/StudiesFilter';
import { useSelector } from 'react-redux'
import { toggleFilter } from '../redux/actions'
import { useDispatch } from 'react-redux'
import PropTypes from "prop-types";


export default function StudiesListPage() {
    const dispatch = useDispatch()
    const filters = useSelector((state) => state.filteringCriteria)
    const selectedFilters = useSelector((state) => state.filteringSelected)
    const studies = useSelector((state) => state.allStudies)
    const filteredStudies = useSelector((state) => state.includedStudies)
    const filterOpen = useSelector((state) => state.filterOpen)
    const toggleLists = useSelector((state) => state.toggleLists)
    const searchCriteria = useSelector((state) => state.searchCriteria)
    const shareUrl = useSelector((state) => state.shareUrl)
    const searchCompleted = useSelector((state) => state.searchCompleted)


    const toggleFilterClicked = () => {
        const toggleFilterThunk = toggleFilter()
        dispatch(toggleFilterThunk)
    }

    return (
        <Container maxWidth={false}>
            <Stack sx={{ mt: 15, mb: 1 }}>
                <StudiesFilter
                    studies={studies}
                    filters={filters}
                    selectedFilters={selectedFilters}
                    isOpenFilter={filterOpen}
                    onOpenFilter={() => toggleFilterClicked()}
                    onCloseFilter={() => toggleFilterClicked()}
                />
                {toggleLists === "StudiesList" &&
                    <StudiesList studies={studies} filteredStudies={filteredStudies} filters={filters} searchCriteria={searchCriteria} searchCompleted={searchCompleted} />
                }
                {searchCompleted && toggleLists === "GraphsList" &&
                    <GraphsList studies={studies} filteredStudies={filteredStudies} filters={filters} searchCriteria={searchCriteria} shareUrl={shareUrl} searchCompleted={searchCompleted} selectedFilters={selectedFilters} />
                }
                {searchCompleted && toggleLists === "EndpointsList" &&
                    <EndpointsList studies={studies} filteredStudies={filteredStudies} filters={filters} searchCriteria={searchCriteria} searchCompleted={searchCompleted} />
                }
            </Stack>
        </Container>
    );
}

StudiesListPage.propTypes = {
    toggleFilter: PropTypes.func,
    filteringCriteria: PropTypes.object,
    studies: PropTypes.array,
    includedStudies: PropTypes.array,
    filterOpen: PropTypes.bool
};