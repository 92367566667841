// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Tooltip } from '@mui/material';
// components
import Logo from './Logo';
import Iconify from './Iconify'
//
import { resetSearch, toggleFilter, resetFilter } from '../redux/actions'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 0;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: "100%"
  },
  [theme.breakpoints.up('lg')]: {
    paddingLeft: `${DRAWER_WIDTH + 1}px`
  },
  borderBottom: "1px solid rgba(0,72,132, 0.24)"
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

export default function Navbar({ searchDone, searchCompleted }) {
  const dispatch = useDispatch()
  const studies = useSelector((state) => state.allStudies)
  const filteredStudies = useSelector((state) => state.includedStudies)


  const resetSearchClicked = () => {
    const resetSearchThunk = resetSearch()
    dispatch(resetSearchThunk)
  }
  const toggleFilterClicked = () => {
    const toggleFilterThunk = toggleFilter()
    dispatch(toggleFilterThunk)
  }

  const resetFilterClicked = () => {
    const resetFilterThunk = resetFilter()
    dispatch(resetFilterThunk)
  }

  return (
    <RootStyle>
      <ToolbarStyle>
        <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
          <Logo />
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        {searchDone &&
          <Box sx={{ px: 2.5, pb: 3 }}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={3}
              sx={{ pt: 5, borderRadius: 2, position: 'relative' }}
            >
              <Tooltip title="New search">
                <IconButton onClick={(e) => resetSearchClicked()}>
                  <Iconify icon="eva:search-fill" />
                </IconButton>
              </Tooltip>
              {filteredStudies.length !== studies.length &&
                <Tooltip title="Clear filtering">
                  <IconButton onClick={(e) => resetFilterClicked()}>
                    <Iconify icon="eva:refresh-fill" />
                  </IconButton>
                </Tooltip>
              }
              {(searchCompleted) &&
                <Tooltip title="Filter trials">
                  <IconButton onClick={(e) => toggleFilterClicked()}>
                    <Iconify icon="ic:round-filter-list" />
                  </IconButton>
                </Tooltip>
              }
            </Stack>
          </Box>
        }
      </ToolbarStyle>
    </RootStyle>
  );
}
