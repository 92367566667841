import React from "react";
import PropTypes from "prop-types";
import {
    Checkbox,
    FormControl,
    ListItemText,
    ListSubheader,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Slider,
    TextField,
    InputAdornment
} from '@mui/material'
import Iconify from "../components/Iconify";

const containsText = (text, searchText) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;


function StudiesFilterList({ onChange, list, label, name, filters, selected }) {

    const [year, setYear] = React.useState(1000);
    const [searchText, setSearchText] = React.useState("");

    const displayedOptions = React.useMemo(
        () => list.filter((option, index) => (typeof option === "string" && searchText !== "") ? containsText(option, searchText) : index <= 15),
        [searchText, list]
    );

    const ITEM_HEIGHT = 30;
    const ITEM_PADDING_TOP = 6;

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 15 + ITEM_PADDING_TOP,
                width: 650,
            },
        },
    };

    const valuetext = (value) => {
        return `${value}`;
    }

    const handleSliderChange = (e) => {
        if (e.target.value !== year) {
            setYear(e.target.value)
            onChange(e)
        }
    }

    let marks = []
    if (name === "startDate" || name === "endDate") {
        marks.push({ label: filters["startDate"].toString(), value: filters["startDate"] })
        marks.push({ label: filters["endDate"].toString(), value: filters["endDate"] })
    }

    return (
        <>

            {(name !== "startDate" && name !== "endDate") &&
                <FormControl>
                    <InputLabel id={name}>{label}</InputLabel>
                    <Select
                        id={name}
                        multiple
                        value={selected}
                        onChange={onChange}
                        input={<Input />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                        onClose={() => setSearchText("")}
                    >
                        <ListSubheader>
                            <TextField
                                size="small"
                                // Autofocus on textfield
                                autoFocus
                                placeholder={`Type to search. (${list.length} items)`}
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                                        </InputAdornment>
                                    )
                                }}
                                onChange={(e) => setSearchText(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key !== "Escape") {
                                        // Prevents autoselecting item while typing (default Select behaviour)
                                        e.stopPropagation();
                                    }
                                }}
                            />
                        </ListSubheader>

                        {displayedOptions.map((item, i) => (
                            <MenuItem key={item + "_" + i} value={item} name={name} dense={true}>
                                <Checkbox checked={selected.indexOf(item) !== -1} />
                                <ListItemText primary={item} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            }

            {(name === "startDate") &&
                <FormControl>
                    <InputLabel id={name}>{label}</InputLabel>
                    <Slider
                        key={name}
                        id={name}
                        name={name}
                        sx={{ mt: "2rem" }}
                        onChange={handleSliderChange}
                        defaultValue={filters["startDate"]}
                        value={selected[0]}
                        getAriaValueText={valuetext}
                        step={1}
                        valueLabelDisplay="auto"
                        min={filters["startDate"]}
                        max={filters["endDate"]}
                        marks={marks}
                    />
                </FormControl>
            }
        </>
    )
}

StudiesFilterList.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    list: PropTypes.array,
    filters: PropTypes.object,
    onChange: PropTypes.func
}

export default StudiesFilterList