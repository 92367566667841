import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

const LogoStyle = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
});

export default function Logo({ sx }) {
  return (
    <RouterLink to="/" style={{ textDecoration: "none" }} >
      <LogoStyle>
        <Box component="img" src="/assets/ME-TA_logo_blue.png" sx={{ width: 75, mr: 2,/*height: 50,*/ ...sx }} />
        <Typography align="center" variant="h6"
          sx={{ color: "#004884", ...sx }}>
          CLINICAL TRIALS OVERVIEW
        </Typography>
      </LogoStyle>
    </RouterLink >
  );
}
