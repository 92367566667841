import React from 'react';
// material
import { Container, Typography } from '@mui/material';

import Iconify from "../components/Iconify";
import { useSelector } from 'react-redux'
import { setSearchCriteriaValue, searchPhaseSelected, searchFundSelected, testApi, searchSubmit, getCondtionList } from '../redux/actions'
import { useDispatch } from 'react-redux'
import PropTypes from "prop-types";
import {
    OutlinedInput,
    Stack,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputAdornment,
    FormHelperText,
    TextField,
    Paper,
    Box,
    Alert
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';

// ----------------------------------------------------------------------

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    '&.Mui-focused': { /*width: 520,*/ boxShadow: theme.customShadows.z8, background: "#fff" },
    '& fieldset': {
        borderWidth: `1px !important`,
        /*borderColor: "rgba(0,72,132, 0.24)"*/
        borderColor: `${theme.palette.grey[500_32]} !important`,
    },
}));

const LogoStyle = styled('div')({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
});
// ----------------------------------------------------------------------
export default function SearchPage() {

    const dispatch = useDispatch()

    const loading = useSelector((state) => state.loading)
    const apiWarmedUp = useSelector((state) => state.apiWarmedUp)
    const searchCriteria = useSelector((state) => state.searchCriteria)
    const conditions = useSelector((state => state.conditions))
    const search_error = useSelector((state => state.search_error))

    React.useEffect(() => {
        const testApiThunk = testApi()
        if (!apiWarmedUp) {
            dispatch(testApiThunk)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const enableSearchButton = () => {
        return (searchCriteria["cond"] === "" && searchCriteria["term"] === "")
    }

    const selected = (id, type) => {
        var result = searchCriteria[type].filter(obj => {
            return obj.id === id
        })
        return result[0].value
    }

    const submitSearch = () => {
        const searchSubmitThunk = searchSubmit()
        dispatch(searchSubmitThunk)
    }

    const handleAutoInputChange = (event, value) => {
        const getCondtionListThunk = getCondtionList("cond", value)
        dispatch(getCondtionListThunk)
        const setSearchCriteriaValueThunk = setSearchCriteriaValue("cond", value)
        dispatch(setSearchCriteriaValueThunk)
    }

    const handleAutoChange = (event, value) => {
        const setSearchCriteriaValueThunk = setSearchCriteriaValue("cond", value)
        dispatch(setSearchCriteriaValueThunk)
    }

    const handleChange = event => {
        const { id, value } = event.target
        const setSearchCriteriaValueThunk = setSearchCriteriaValue(id, value)
        dispatch(setSearchCriteriaValueThunk)
    }

    const handlePhaseChange = (value) => {
        const searchPhaseSelectedThunk = searchPhaseSelected(value, "phase")
        dispatch(searchPhaseSelectedThunk)
    }

    const handleFundChange = (value) => {
        const searchFundSelectedThunk = searchFundSelected(value, "fund")
        dispatch(searchFundSelectedThunk)
    }

    return (

        <Container maxWidth="md">
            <Paper variant="outlined" sx={{ mt: 15, mb: 1, textAlign: 'center', padding: '1rem', boxShadow: "0px 0px 6px rgba(0,72,132, 0.24)", borderColor: "rgba(0,72,132, 0.24)" }}>
                {(search_error !== "") &&
                    <Stack sx={{ width: '100%', mb: 1 }} spacing={2}>
                        <Alert severity="error">{search_error}</Alert>
                    </Stack>
                }

                <LogoStyle>
                    <Box component="img" src="/assets/metawebservice.png" sx={{ width: 45, mb: 1.5, /*mr: 2,/*height: 50,*/ }} />
                    <Typography align="center" variant="h6"
                        sx={{ color: "#004884" }}>
                        Search clinical trials
                    </Typography>
                </LogoStyle>
                <Stack spacing={3}>
                    <FormControl variant="outlined">
                        <Autocomplete
                            value={searchCriteria["cond"]}
                            onChange={handleAutoChange}
                            onInputChange={handleAutoInputChange}
                            freeSolo
                            id="cond"
                            disableClearable
                            options={conditions.map((option) => option)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    /*label="Enter condition or disease e.g Diabetes"*/
                                    placeholder="Enter condition or disease e.g Diabetes"
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',

                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                                            </InputAdornment>
                                        ),
                                    }}

                                />
                            )}
                        />


                        {/*<SearchStyle
                            id="cond"
                            fullWidth
                            value={searchCriteria["cond"]}
                            onChange={handleChange}
                            placeholder="Enter condition or disease e.g Diabetes"
                            startAdornment={
                                <InputAdornment position="start">
                                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                                </InputAdornment>
                            }
                        />*/}
                        <FormHelperText>
                            The disease, disorder, syndrome, illness, or injury that is being studied
                        </FormHelperText>
                    </FormControl>
                    <FormControl variant="outlined">
                        <SearchStyle
                            id="term"
                            fullWidth
                            placeholder="Other terms e.g Company name or drug name or NCT number"
                            value={searchCriteria.term}
                            onChange={handleChange}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                                </InputAdornment>
                            }
                        />
                        <FormHelperText>
                            In the search feature, the Other terms field is used to narrow a search.
                        </FormHelperText>
                    </FormControl>
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                    {searchCriteria["phase"].map((item, i) =>
                        <FormControlLabel
                            onChange={() => handlePhaseChange(item.id)}
                            key={"phase" + i}
                            control={<Checkbox value={item.id} checked={selected(item.id, "phase")} />}
                            label={item.text}
                        />
                    )}
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                    {searchCriteria["fund"].map((item, j) =>
                        <FormControlLabel
                            onChange={() => handleFundChange(item.id)}
                            key={"fund" + j}
                            control={<Checkbox checked={selected(item.id, "fund")} value={item.id} />}
                            label={item.text}
                        />
                    )}
                </Stack>
                <LoadingButton
                    onClick={() => submitSearch()}
                    disabled={enableSearchButton()}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<Iconify icon="eva:search-fill" sx={{ color: (enableSearchButton()) ? 'text.disabled' : "#fff", width: 20, height: 20 }} />}
                >
                    Search
                </LoadingButton>
            </Paper>
        </Container>
    );
}

SearchPage.propTypes = {
    loading: PropTypes.bool,
    apiWarmedUp: PropTypes.bool,
    searchCriteria: PropTypes.object,
};

