import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
    Grid
} from '@mui/material';
import classNames from 'classnames';
import "./GraphsList.scss";
import QuickSearchToolbar from "./QuickSearchToolbar";
import html2canvas from 'html2canvas'
import ReactDOM from 'react-dom';


const getLabel = (name) => {
    switch (name) {
        case "title": return "Title"
        case "study_phase": return "Phase"
        case "sponsor_collaborators": return "Sponsor"
        case "condition": return "Condition"
        case "outcomes": return "Endpoint"
        case "startDate": return "Start date"
        case "interventions": return "Intervention"
        case "studyId": return "Trial id"
        default:
            return name
    }
}
const sponsorLogos = [
    { id: 'Genmab', imagePath: 'assets/genmab.png' },
    { id: 'Seattle Genetics, Inc.', imagePath: 'assets/seattle-genetics.png' },
    { id: 'European Network of Gynaecological Oncological Trial Groups (ENGOT)', imagePath: 'assets/engot.png' },
    { id: 'Belgian Gynaecological Oncology Group', imagePath: 'assets/bgog.png' },
    // {id: 'Gynecologic Oncology Group', imagePath: 'assets/novo.jpg'},
    { id: 'Merck Sharp & Dohme Corp.', imagePath: 'assets/merck.png' },
    { id: 'Emergent BioSolutions', imagePath: 'assets/emergent-biosolutions.png' },
    { id: 'GlaxoSmithKline', imagePath: 'assets/gsk.png' },
    { id: 'Eli Lilly and Company', imagePath: 'assets/lilly.png' },
    { id: 'novo nordisk', imagePath: 'assets/novo.png' },
    { id: 'Novo Nordisk A/S', imagePath: 'assets/novo.png' },
    { id: 'Boehringer Ingelheim', imagePath: 'assets/Boehringer_Ingelheim.png' },
    { id: 'novartis', imagePath: 'assets/novartis-logo.png' },
    { id: 'sanofi', imagePath: 'assets/sanofi.png' },
    { id: 'pfizer', imagePath: 'assets/pfizer.png' },
    { id: 'johnson johnson', imagePath: 'assets/johnson_johnson.png' },
    { id: 'LEO Pharma', imagePath: 'assets/Leo_logo_pharma.png' },
    { id: 'Takeda', imagePath: 'assets/Takeda.png' },
    { id: 'Zealand Pharma', imagePath: 'assets/zealand_logo.png' },
    { id: 'VectivBio AG', imagePath: 'assets/vectivBio.png' }

]

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export default function GraphsList({ studies, filteredStudies, filters, searchCriteria, shareUrl, searchCompleted, selectedFilters }) {
    const [searchText, setSearchText] = React.useState('');

    let AddedSponsorCondition = []

    const resolvePhaseClass = phase => {
        const phases = ['Phase 1', 'Phase 2', 'Phase 3', 'Phase 4', 'Not Applicable'];
        if (phases.includes(phase)) {
            return phase
        }
        else if (phase === 'Phase 1|Phase 2') {
            return "Phase 1";
        }
        else if (phase === 'Phase 2|Phase 3') {
            return "Phase 2";
        }
        else if (phase === 'Early Phase 1') {
            return "Phase 1";
        }
    }
    const addYearToDate = d => {
        let year = d.getFullYear();
        const c = new Date(year + 1, 0, 1);
        return c;
    }
    const setStartDateToJanuary = d => {
        let year = d.getFullYear();
        const c = new Date(year, 0, 1);
        return c;
    }
    const getDrugAndComparator = (interventions) => {
        const interventionsMod = interventions.map(int => {
            return int.split(":")[1]
        })
        return interventionsMod.join(" vs ")
    }
    const getYearByIndex = (index) => {
        const d = startDate
        const year = d.getFullYear();
        const c = new Date(year + index, 0, 1);

        return c;//.getFullYear() 
    }
    const diffDates = (dt2, dt1) => {
        var diff = (dt2.getTime() - dt1.getTime()) / 1000;
        diff /= 60 * 60 * 24;
        return { numYears: Math.abs(Math.round(diff / 365.25)), numDays: diff };
    };

    const getPosByDate = d => {
        const leftDate = diffDates(d, startDate);
        return leftDate.numDays * oneDay;
    }
    const getSponsorCondition = (sponsor_collaborators, condition) => {
        const key = sponsor_collaborators + "_" + condition.join("_");
        if (AddedSponsorCondition.indexOf(key.toLowerCase()) === -1) {
            AddedSponsorCondition.push(key.toLowerCase())
            return false;
        }
        return true;
    }
    const renderSponsor = (sponsor) => {

        // johnsson
        // n=enrollmrnt
        // metaprimary outcome
        // drugv and comaparat

        let sponsorLogo = sponsorLogos.find(item => item.id.toLocaleLowerCase() === sponsor.toLocaleLowerCase());
        if (sponsorLogo) {
            return <img className="graphs__timeline-sponsor-logo" src={sponsorLogo.imagePath} alt={sponsorLogo.id} />
        }
        else { console.log("No image for:", sponsor) }
        return <div className="graphs__timeline-sponsor-label">{sponsor}</div>
    }
    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        filteredStudies = filteredStudies.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field].toString());
            });
        });
    };

    console.log(filteredStudies)
    let filteredStudiesMapped = filteredStudies.map((study, i) => {
        const transformedStudy = {
            "increment": i,
            "sponsor_collaborators": study.sponsor_collaborators[0],
            "outcome": (study.outcomes) ? (study.outcomes[0].length > 80) ? study.outcomes[0].slice(0, 80).concat('...') : study.outcomes[0] : "",
            "condition": study.condition,
            "id": study.id,
            "studyId": study.studyId.split("|")[0],
            "title": study.title,
            "study_phase": study.study_phase,
            "startDate": (study.startDate != null) ? new Date(study.startDate.substring(0, 10)) : null,
            "completionDate": (study.completionDate != null) ? new Date(study.completionDate.substring(0, 10)) : null,
            "enrollment": study.enrollment,
            "interventions": study.interventions,
            "duration": Math.floor(study.duration / 7)
        }
        return transformedStudy;
    })
    var studiesFiltered = filteredStudiesMapped.filter(study => study.enrollment > 0)
    var studiesSorted = _.orderBy(
        studiesFiltered,
        ["sort_condition", "sponsor_collaborators", "condition", "startDate", "completionDate"],
        ["asc", "asc", "asc"]
    );

    console.log(studiesSorted)

    let sponsors = studiesSorted.map(a => a.sponsor_collaborators);
    sponsors = [...new Set([].concat(...sponsors))];

    let uniquePhases = studiesSorted.map(a => resolvePhaseClass(a.study_phase));
    uniquePhases = _.orderBy(uniquePhases);
    uniquePhases = [...new Set([].concat(...uniquePhases))];

    const itemHeight = 70;

    //Get first and last date
    const newStudies = _.cloneDeep(studiesFiltered);

    let timeDomainStart = _.minBy(newStudies, function (d) {
        return d.startDate;
    });
    let timeDomainEnd = _.maxBy(newStudies, function (d) {
        return d.completionDate;
    });


    const endDate = addYearToDate(timeDomainEnd.completionDate);
    const startDate = setStartDateToJanuary(timeDomainStart.startDate);

    const { numYears, numDays } = diffDates(
        endDate,
        startDate
    );

    const maxWidth = 100;
    const oneDay = maxWidth / numDays;

    const transformedSponsors = sponsors.map(item => {
        let _item = { id: item, title: item };

        let _conditions = studiesSorted.map(a => a.condition);
        _conditions = [...new Set([].concat(..._conditions))];

        let _outcomes = studiesSorted.map(a => a.outcomes);
        _outcomes = [...new Set([].concat(..._outcomes))];

        _item.conditions = _conditions;//conditionRows;
        _item.outcomes = _outcomes;

        let lvl = 0;
        const filtered = studiesSorted.filter(st => st.sponsor_collaborators === item);

        const items = filtered.map((item, index) => {
            let parsed = { ...item, index: lvl };
            const { numDays } = diffDates(parsed.completionDate || endDate, parsed.startDate || startDate);
            const leftDate = diffDates(parsed.startDate || startDate, startDate);

            //parsed.outcome = item.outcomes[0]
            parsed.itemWidth = numDays * oneDay;
            parsed.left = leftDate.numDays * oneDay;

            if (!parsed.startDate) {
                parsed.startDate = startDate;
            }
            if (!parsed.completionDate) {
                parsed.completionDate = endDate;
            }
            lvl++;
            return parsed;
        });
        _item.items = items;
        _item.rowHeight = lvl ? lvl * itemHeight + lvl * 20 : itemHeight + 20;

        return _item;
    });
    console.log(transformedSponsors)
    let timeTicks = Array(numYears + 1).fill().map((_, i) => i * i);;

    const years = timeTicks.map((item, index) => {
        const d = getYearByIndex(index);
        return { left: getPosByDate(d), label: d.getFullYear() };
    });

    const today = getPosByDate(new Date());

    const arrowUp = () => {
        return <svg viewBox="0 0 32 32" className="icon icon-caret-top" ><path fill="currentColor" d="M8 20.695l7.997-11.39L24 20.695z" /></svg>;
    }

    let filtersString = ""
    for (const element of Object.keys(selectedFilters)) {
        const selection = selectedFilters[element]
        if (selection.length > 0) {
            if (!filtersString.includes(getLabel(element))) {
                filtersString = filtersString + getLabel(element) + ": " + selection.join(', ') + "; "
            }
        }

        if (element === "startDate") {
            if (!filtersString.includes(getLabel(element))) {
                filtersString = filtersString + getLabel(element) + ">= " + selection + ";"
            }
        }
    }

    const saveAs = (uri, filename) => {
        var link = document.createElement('a');
        if (typeof link.download === 'string') {

            link.href = uri;
            link.download = filename;

            //Firefox requires the link to be in the body
            document.body.appendChild(link);

            //simulate click
            link.click();

            //remove the link when done
            document.body.removeChild(link);
        } else {
            window.open(uri);
        }
    }

    const downloadClicked = () => {

        const item = document.getElementsByClassName("graphs__content");
        const node = ReactDOM.findDOMNode(item[0]);
        const filename = searchCriteria["cond"] + ".png"
        // Get child nodes
        if (node instanceof HTMLElement) {
            html2canvas(node).then(function (canvas) {

                //setBase64img(canvas.toDataURL('image/png'))
                saveAs(canvas.toDataURL(), filename);
            });
        }
    }

    return (
        <Grid item xs={12}>
            <div className="graphs">
                <div className="graphs__content">
                    <QuickSearchToolbar
                        comp="GraphsList"
                        studiesCount={studies.length}
                        filteredCount={filteredStudies.length}
                        value={searchText}
                        filters={filters}
                        export={false}
                        searchCriteria={searchCriteria}
                        downloadClicked={() => downloadClicked()}
                        onChange={(event) => requestSearch(event.target.value)}
                        searchCompleted={searchCompleted}
                        shareUrl={shareUrl}
                    ></QuickSearchToolbar>
                    <div className="graphs__timeline-graph">
                        <span key={'time-tick-sponsor'} style={{
                            width: 100 / (numYears + 1) + '%', top: "-20px", display: 'inline-block', textAlign: 'left', position: "absolute",
                            left: 0 + "%", maxWidth: "8%", fontSize: "12px", color: "#333", fontWeight: "bold"
                        }}>{"Sponsor"}</span>
                        <span key={'time-tick-indication'} style={{
                            width: 100 / (numYears + 1) + '%', top: "-20px", display: 'inline-block', textAlign: 'left', position: "absolute",
                            left: 8 + "%", maxWidth: "8%", fontSize: "12px", color: "#333", fontWeight: "bold"
                        }}>{"Indication"}</span>
                        <div className="graphs__timeline-ticks-top">
                            {years.map((item, index) => {
                                return (
                                    <span key={'time-tick' + index} style={{
                                        width: 100 / (numYears + 1) + '%', top: "-20px", display: 'inline-block', textAlign: index === 0 ? 'left' : index === years.length - 1 ? 'left' : 'left', position: "absolute",
                                        left: item.left + "%"
                                    }}>{item.label}</span>
                                );
                            })}
                        </div>

                        <div className="graphs__timeline-now">
                            <div className="graphs__datenow-line" style={{ left: today + '%' }} />
                            <span className="graphs__arrow-marker" style={{ left: `calc(${today + '%'} - 0.5rem)` }}>{arrowUp()}</span>
                        </div>

                        <div className="graphs__timeline-graph-content">
                            <div className="graphs__timeline-graph-studylist" >
                                {transformedSponsors.map((item, index) => {
                                    return (<div
                                        className="graphs__timeline-graph-studylist-sponsor"
                                        key={'sponsor-' + index}
                                        style={{
                                            height: item.rowHeight + 'px',
                                            paddingTop: "10px"
                                        }}>
                                        {renderSponsor(item.title)}
                                        {item.items.map((itm, index) => {

                                            return (
                                                <div key={index} style={{ position: 'relative', zIndex: 3, paddingTop: "10px" }}>

                                                    {!getSponsorCondition(item.title, itm.condition) &&
                                                        <div
                                                            key={item.id + "condition"}
                                                            style={{
                                                                height: itemHeight + "px",
                                                                // top: itm.index * itemHeight + "px",
                                                                top: itm.index ? itm.index * itemHeight + "px" : "0",
                                                                position: "absolute",
                                                                textAlign: "left"
                                                            }}
                                                            className={classNames('graphs__study-timeline-condtion')}>
                                                            {itm.condition.join(", ")}
                                                        </div>
                                                    }
                                                    <span style={{ position: "absolute", left: "0.5%", minHeight: "70px", height: itemHeight + "px", top: itm.index ? (itm.index * itemHeight) + 30 + "px" : "0" }} className={classNames("graphs__study-timeline-studyid")}>{itm.studyId}</span>
                                                    <a
                                                        rel="noreferrer"
                                                        key={itm.id}
                                                        href={"https://clinicaltrials.gov/ct2/results?cond=&term=" + itm.id + "&cntry=&state=&city=&dist=itm"}
                                                        target="_blank"
                                                        className={classNames("graphs__study-timeline-item", 'graphs__study-timeline-item--' + resolvePhaseClass(itm.study_phase).split(" ")[1])}
                                                        style={{
                                                            width: itm.itemWidth + "%",
                                                            height: itemHeight + "px",
                                                            minHeight: "70px",
                                                            top: itm.index ? itm.index * itemHeight + "px" : "0",
                                                            position: "absolute",
                                                            left: itm.left + "%"
                                                        }}
                                                    >
                                                        <span key={itm.id + 'enroll'}>{"n = " + itm.enrollment}</span>
                                                        <span key={itm.id + 'out'}>{(itm.meta_prim_outcome) ? itm.meta_prim_outcome : itm.outcome}</span>
                                                        <span key={itm.id + 'inter'}>{getDrugAndComparator(itm.interventions)}</span>
                                                    </a>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    );
                                })}
                            </div>
                        </div>

                        <div className="graphs__timeline-ticks-bottom">
                            {years.map((item, index) => {
                                return (
                                    <span key={'time-tick' + index} style={{
                                        width: 100 / (numYears + 1) + '%', display: 'inline-block', textAlign: index === 0 ? 'left' : index === years.length - 1 ? 'left' : 'left', position: "absolute",
                                        left: item.left + "%"
                                    }}>{item.label}</span>
                                );
                            })}
                        </div>
                    </div>

                    <div className="graphs__study-phases">
                        {uniquePhases.map((phase, index) => {
                            return (
                                <div
                                    key={"phase_" + index}
                                    className={classNames("graphs__study-timeline-phase", 'graphs__study-timeline-phase--' + phase.split(" ")[1])}
                                >
                                    <span>{phase}</span>
                                </div>
                            );
                        })}
                    </div>
                    <div className="graphs__footer">
                        <p>Phase1|Phase 2 has been mapped to Phase 1 and Phase2|Phase 3 to Phase 2</p>
                        <p>Data obtained from clinicaltrials.gov at {new Date().toDateString()}</p>
                        <p>Note: Studies registered with a missing Start Date or zero enrollment on clinicaltrials.gov are removed; Filtered to: {filtersString}</p>
                    </div>
                </div>
            </div>
        </Grid >
    )

}

GraphsList.propTypes = {
    filteredStudies: PropTypes.array,
    studies: PropTypes.array,
    filters: PropTypes.object
};