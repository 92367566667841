import React from "react";
import PropTypes from "prop-types";
import Iconify from "./Iconify";
import IconButton from "@mui/material/IconButton";
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { toggleToTypeFunc } from '../redux/actions'
import {
    Toolbar,
    Typography,
    OutlinedInput,
    InputAdornment,
    Tooltip,
    Stack,
    Box,
    CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { GridToolbarExport } from '@mui/x-data-grid';

// ----------------------------------------------------------------------
const RootStyle = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 440,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': { width: 520, boxShadow: theme.customShadows.z8 },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`,
    },
}));
// ----------------------------------------------------------------------
function copyClipBoard(shareUrl) {
    var imageURL = window.location.protocol + "//" + window.location.host + window.location.pathname + shareUrl
    navigator.clipboard.writeText(imageURL);
    //alert("Copied: " + imageURL);
}

//https://github.com/mui/mui-x/blob/master/docs/src/pages/components/data-grid/filtering/QuickFilteringGrid.js
function QuickSearchToolbar(props) {
    const dispatch = useDispatch()

    const toggleLists = useSelector((state) => state.toggleLists)

    const toggleToTableClicked = (type) => {
        const toggleToTypeFuncThunk = toggleToTypeFunc(type)
        dispatch(toggleToTypeFuncThunk)
    }

    let filtersString = ""
    for (const element of Object.keys(props.filters)) {

        const selection = props.filters[element]
        if (selection.length > 0) {
            filtersString += filtersString + element + ":" + selection.join(',') + ";"
        }
    }

    //let url = "https://www.linkedin.com/sharing/share-offsite/?url=https://metawebservice.net"
    let url = `https://www.linkedin.com/sharing/share-offsite/?url=https://metawebservice.net${props.shareUrl}`

    return (
        <RootStyle
            sx={{
                ...(props.numSelected > 0 && {
                    color: 'primary.main',
                    bgcolor: 'primary.lighter',
                }),
            }}
        >

            <Stack direction="column" alignItems="left" sx={{ textAlign: "left" }}>
                <Stack direction="row" alignItems="left" sx={{ textAlign: "left" }}>
                    <Typography variant="h4">
                        {(props.comp !== "EndpointsList") ?
                            "Clinical trials"
                            : "Clinical endpoints"
                        }
                    </Typography>
                    <Typography component="h4" variant="subtitle1" gutterBottom sx={{ mt: 0.8 }}>
                        &nbsp;&nbsp;({props.filteredCount} of {props.studiesCount} shown)
                    </Typography>
                </Stack>

                <Typography variant="subtitle" /*gutterBottom*/>
                    Search:&nbsp;&nbsp;
                    {(props.searchCriteria["cond"]) ? props.searchCriteria["cond"] : props.searchCriteria["term"]}
                </Typography>
            </Stack>
            {(props.comp !== "GraphsList") &&
                <SearchStyle
                    value={props.value}
                    onChange={props.onChange}
                    placeholder="Search outputs..."
                    startAdornment={
                        <InputAdornment position="start">
                            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                        </InputAdornment>
                    }
                />
            }

            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
                {(props.searchCompleted) &&
                    <Tooltip title="Table view">
                        <span>
                            <IconButton color="primary" disabled={toggleLists === "StudiesList"} onClick={(e) => toggleToTableClicked("StudiesList")}>
                                <Iconify icon="eva:menu-outline" />
                            </IconButton>
                        </span>
                    </Tooltip>
                }
                {(props.searchCompleted) &&
                    <Tooltip title="Graph view">
                        <span>
                            <IconButton color="primary" disabled={toggleLists === "GraphsList"} onClick={(e) => toggleToTableClicked("GraphsList")}>
                                <Iconify icon="eva:image-outline" />
                            </IconButton>
                        </span>
                    </Tooltip>
                }
                {(props.searchCompleted) &&
                    <Tooltip title="Endpoint view">
                        <span>
                            <IconButton color="primary" disabled={toggleLists === "EndpointsList"} onClick={(e) => toggleToTableClicked("EndpointsList")}>
                                <Iconify icon="eva:bulb-outline" />
                            </IconButton>
                        </span>
                    </Tooltip>
                }
                {props.export && props.searchCompleted &&
                    <GridToolbarExport />
                }

                {!props.export && props.searchCompleted &&
                    <>
                        <Tooltip title="Download Gant chart">
                            <IconButton color="primary" onClick={props.downloadClicked}>
                                <Iconify icon="eva:download-fill" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Share chart on LinkedIn">
                            <IconButton href={url} color="primary">
                                <Iconify icon="eva:linkedin-fill" />
                            </IconButton>
                        </Tooltip>
                        {(props.shareUrl) &&
                            <Tooltip title="Copy url">
                                <IconButton onClick={() => copyClipBoard(props.shareUrl)} color="primary">
                                    <Iconify icon="eva:copy-fill" />
                                </IconButton>
                            </Tooltip>
                        }
                    </>
                }

                {!props.searchCompleted &&
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress />
                    </Box>
                }
            </Stack>
        </RootStyle>

    );
}

QuickSearchToolbar.propTypes = {
    toggleLists: PropTypes.bool,
    export: PropTypes.bool,
    filteredCount: PropTypes.number,
    studiesCount: PropTypes.number,
    value: PropTypes.string,
    onChange: PropTypes.func,
    filters: PropTypes.object

};

export default QuickSearchToolbar
