import PropTypes from 'prop-types';
// material
import {
    Box,
    Stack,
    Button,
    Drawer,
    Divider,
    IconButton,
    Typography,
} from '@mui/material';
// components
import Iconify from './Iconify';
import Scrollbar from './Scrollbar';
import { updateFilter, resetFilter } from '../redux/actions'
import StudiesFilterList from './StudiesFilterList'
import { useDispatch } from 'react-redux'

export default function StudiesFilter({ isOpenFilter, onOpenFilter, onCloseFilter, studies, filters, selectedFilters }) {
    const dispatch = useDispatch()

    const handleChange = (event, index) => {
        let updateFilterThunk
        if (index === undefined) {
            updateFilterThunk = updateFilter(event.target.value, "startDate")
        }
        else {
            updateFilterThunk = updateFilter(event.target.value, index.props.name)
        }
        dispatch(updateFilterThunk)
    }
    const resetFilterClicked = () => {
        const resetFilterThunk = resetFilter()
        dispatch(resetFilterThunk)
    }

    const getLabel = (name) => {
        switch (name) {
            case "title": return "Title"
            case "study_phase": return "Phase"
            case "sponsor_collaborators": return "Sponsor"
            case "condition": return "Condition"
            case "outcomes": return "Endpoint"
            case "startDate": return "Start date"
            case "interventions": return "Intervention"
            case "studyId": return "Trial id"
            default:
                return name
        }
    }

    const names = studies.length > 0 ? Object.keys(filters) : []

    return (
        <Drawer
            anchor="right"
            open={isOpenFilter}
            onClose={onCloseFilter}
            PaperProps={{
                sx: { width: 280, border: 'none', overflow: 'hidden' },
            }}
        >
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
                <Typography variant="subtitle1" sx={{ ml: 1 }}>
                    Filters
                </Typography>
                <IconButton onClick={onCloseFilter}>
                    <Iconify icon="eva:close-fill" width={20} height={20} />
                </IconButton>
            </Stack>

            <Divider />

            <Scrollbar>
                <Stack spacing={3} sx={{ p: 3, maxHeight: "75vh" }}>
                    {names.map(name => {
                        return (
                            <StudiesFilterList
                                key={name}
                                name={name}
                                label={getLabel(name)}
                                list={(filters[name] instanceof Object) ? filters[name] : [filters[name]]}
                                onChange={handleChange}
                                filters={filters}
                                selected={(selectedFilters[name] instanceof Object) ? selectedFilters[name] : [selectedFilters[name]]}
                            />
                        )
                    })}

                </Stack>
            </Scrollbar>

            <Box sx={{ p: 3 }}>
                <Button
                    onClick={() => resetFilterClicked()}
                    fullWidth
                    size="large"
                    type="submit"
                    color="inherit"
                    variant="outlined"
                    startIcon={<Iconify icon="ic:round-clear-all" />}
                >
                    Clear All
                </Button>
            </Box>
        </Drawer>
    );
}

StudiesFilter.propTypes = {
    isOpenFilter: PropTypes.bool,
    onOpenFilter: PropTypes.func,
    onCloseFilter: PropTypes.func,
};
