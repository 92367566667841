import React from "react";
import PropTypes from "prop-types";
import { DataGrid } from '@mui/x-data-grid';
import {
  Grid,
  Paper
} from '@mui/material';
import QuickSearchToolbar from './QuickSearchToolbar';
import './StudiesList.css';
import _ from 'lodash';

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function EndpointsList({ studies, filteredStudies, filters, searchCriteria, searchCompleted }) {

  let all = studies.map(study => {
    return (
      study.outcomes.map(endpoint => {
        const transformedStudy = {
          "sponsor_collaborators": study.sponsor_collaborators[0],
          "id": study.id,
          "endpoint": endpoint,
        }
        return transformedStudy;
      }))
  })
  const allFlattened = [].concat(...all)

  let filteredStudiesMapped = filteredStudies.map((study, i) => {
    return (
      study.outcomes.map((endpoint, j) => {
        const transformedStudy = {
          "sponsor_collaborators": study.sponsor_collaborators[0],
          "condition": study.condition[0],
          "id": study.id + "-" + j,
          "nctid": study.id,
          "studyId": study.studyId.split("|")[0],
          "endpoint": endpoint,
          "study_phase": study.study_phase,
          "startDate": (study.startDate != null) ? study.startDate.substring(0, 10) : "",
        }
        return transformedStudy;
      }))
  })
  const filteredStudiesFlattened = [].concat(...filteredStudiesMapped)

  const studiesSorted = _.orderBy(filteredStudiesFlattened, ['sponsor_collaborators', 'condition', 'startDate'], ['asc', 'asc', 'asc']);

  const [rows, setRows] = React.useState([]);
  const [selected, setSelection] = React.useState([]);
  const [searchText, setSearchText] = React.useState('');

  React.useEffect(() => {
    setRows(studiesSorted)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredStudies])


  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = studiesSorted.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };

  return (
    <Grid item xs={12}>
      <Paper variant="outlined" sx={{ textAlign: 'center' }}>
        <div style={{ height: "75vh", width: '100%' }}>
          <DataGrid
            components={{ Toolbar: QuickSearchToolbar }}
            rows={rows}
            getRowId={(row) => row.id}
            columns={
              [
                {
                  headerName: 'Sponsor/Collaborator',
                  field: 'sponsor_collaborators',
                  minWidth: 150,
                  maxWidth: 250,
                  flex: 0,
                },
                {
                  headerName: 'Indication',
                  field: 'condition',
                  minWidth: 100,
                  maxWidth: 350,
                  flex: 1
                },
                {
                  headerName: 'Nctid',
                  field: 'nctid',
                  minWidth: 150,
                  maxWidth: 200,
                  flex: 0
                },
                {
                  headerName: 'Studyid',
                  field: 'studyId',
                  minWidth: 150,
                  maxWidth: 200,
                  flex: 0
                },
                {
                  headerName: 'Phase',
                  field: 'study_phase',
                  minWidth: 100,
                  maxWidth: 100,
                  flex: 0
                },
                {
                  headerName: 'Start Date',
                  field: 'startDate',
                  minWidth: 100,
                  maxWidth: 150,
                  flex: 0,
                },
                {
                  headerName: 'Endpoint',
                  field: 'endpoint',
                  minWidth: 100,
                  maxWidth: 650,
                  flex: 1
                },

              ]}
            pageSize={100}
            rowsPerPageOptions={[100]}
            disableSelectionOnClick={true}
            /*checkboxSelection*/
            onCellClick={(params, events) => {
              if (params.field === 'id') {
                const url = 'https://clinicaltrials.gov/ct2/show/' + params.id
                window.open(url, '_blank').focus();
              }
            }}
            onSelectionModelChange={(newSelection) => {
              setSelection(newSelection);
            }}
            componentsProps={{
              toolbar: {
                numSelected: selected.length,
                studiesCount: allFlattened.length,
                filteredCount: filteredStudiesFlattened.length /*filteredStudies.length*/,
                value: searchText,
                filters: filters,
                onChange: (event) => requestSearch(event.target.value),
                clearSearch: () => requestSearch(''),
                export: true,
                searchCriteria: searchCriteria,
                comp: "EndpointsList",
                searchCompleted: searchCompleted
              },
            }}
          />
        </div>
      </Paper>
    </Grid>
  );
}

EndpointsList.propTypes = {
  filteredStudies: PropTypes.array,
  studies: PropTypes.array,
  filters: PropTypes.object
};

export default EndpointsList