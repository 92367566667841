import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import configureStore from './redux/store';
import Root from './root'

const store = configureStore();

ReactDOM.render(
  <Root
    store={store} />
  ,
  document.getElementById('root'));
