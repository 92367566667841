import React from 'react';

// actions
import { updateStateWithSearchCriteria } from './redux/actions'

//redux
import { useSelector } from 'react-redux'
import PropTypes from "prop-types";
import { useDispatch } from 'react-redux'

// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';

// components
import ScrollToTop from './components/ScrollToTop';
import Navbar from './components/Navbar';
// material
import {
  Box,
  Link,
  Typography
} from '@mui/material';

//pages
import SearchPage from './Pages/SearchPage';
import StudiesListPage from './Pages/StudiesListPage';
function App() {

  const dispatch = useDispatch()
  const searchDone = useSelector((state) => state.searchDone)
  const searchCompleted = useSelector((state) => state.searchCompleted)

  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)

    const searchTerm = queryParams.get("searchCriteria")
    const filteringTerm = queryParams.get("filteringCriteria")

    const objSearch = (searchTerm !== null) ? JSON.parse(decodeURIComponent(searchTerm)) : null
    const objFilterting = (filteringTerm !== null) ? JSON.parse(decodeURIComponent(filteringTerm)) : null

    if (objSearch !== null) {
      const updateStateWithSearchCriteriaThunk = updateStateWithSearchCriteria(objSearch, objFilterting)
      dispatch(updateStateWithSearchCriteriaThunk)
    }
  }, [dispatch]);

  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <Box sx={{ textAlign: "center" }}>
        <Navbar searchDone={searchDone} searchCompleted={searchCompleted} />
        {(!searchDone)
          ?
          <SearchPage />
          :
          <StudiesListPage />
        }
        <Typography align="center" variant="subtitle1">
          Made available by <Link rel="noopener" target="_blank" href="https://me-ta.dk/" underline="always">ME-TA</Link>.&nbsp;
        </Typography>
        <Typography align="center" variant="subtstringitle2">
          Client portal: <Link rel="noopener" target="_blank" href="https://metawebservice.com/" underline="always">metawebservice (requires login)</Link>.
        </Typography>
        <Typography align="center" variant="string">
          What is <Link rel="noopener" target="_blank" href="https://promo.metawebservice.com/" underline="always">metawebservice</Link>?
        </Typography>
        <br />
        {(searchDone) &&
          <Typography align="center" variant="overline">
            Data obtained from <Link rel="noopener" target="_blank" href="https://clinicaltrials.gov/" underline="always">clinicaltrials.gov</Link> at {new Date().toDateString()}.
          </Typography>
        }
      </Box>
    </ThemeConfig>
  );
}

App.propTypes = {
  searchDone: PropTypes.bool,
};

export default App