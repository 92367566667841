import axios from 'axios';
import _ from "lodash";

export const API_CALL_REQUEST = "API_CALL_REQUEST"
export const API_CALL_SUCCESS = "API_CALL_SUCCESS"
export const API_CALL_FAILURE = "API_CALL_FAILURE"

export const TEST_API = 'TEST_API';
export const API_TESTED = 'API_TESTED';
export const GETTING_CONDITION_LIST = 'GETTING_CONDITION_LIST'
export const GOT_CONDITION_LIST = 'GOT_CONDITION_LIST'

export const SEARCH_PHASE_SELECTED = 'SEARCH_PHASE_SELECTED'
export const SEARCH_FUND_SELECTED = 'SEARCH_FUND_SELECTED'
export const SEARCH_SUBMIT = 'SEARCH_SUBMIT'
export const SEARCH_RECEIVED = 'SEARCH_RECEIVED'
export const SET_SEARCH_CRITERIA_VALUE = 'SET_SEARCH_CRITERIA_VALUE';

export const TOGGLE_FILTER = 'TOGGLE_FILTER'
export const UPDATE_FILTER = 'UPDATE_FILTER'
export const RESET_FILTER = 'RESET_FILTER'
export const TOGGLE_TO_TYPE = 'TOGGLE_TO_TYPE'
export const RESET_SEARCH = 'RESET_SEARCH'

export const UPDATE_STATE_WITH_SEARCH_CRITERIA = 'UPDATE_STATE_WITH_SEARCH_CRITERIA'
export const UPDATE_URL_PARAMS = 'UPDATE_URL_PARAMS'

export const SET_INITIAL_FILTERS = 'SET_INITIAL_FILTERS'

export const API_ERROR = 'API_ERROR'

const getBaseUrl = () => {
  return 'https://ssetd0ckwc.execute-api.eu-west-1.amazonaws.com/Prod/';
}

export const resetSearch = () => {
  return async function (dispatch) {
    dispatch({
      type: RESET_SEARCH,
    });
  }
}

export const testApi = () => {
  return async function (dispatch) {
    dispatch({
      type: TEST_API,
    });

    try {
      const endPointUrl = `${getBaseUrl()}simple/NCT00542308`
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
      const response = await axios.get(endPointUrl, config);
      const res = response.data

      dispatch({
        type: API_TESTED,
        payload: { res }
      });
    }
    catch (err) {
      console.log(err.message)
      /*const message = err.message
      dispatch({
        type: API_ERROR,
        payload: { message }
      });*/
    }
  }
};

export const getCondtionList = (id, value) => {
  return async function (dispatch) {

    dispatch({
      type: GETTING_CONDITION_LIST,
    });

    try {
      if (id !== "cond") return
      if (value.length < 3) return

      const endPointUrl = `${getBaseUrl()}cond/${value}`
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
      const response = await axios.get(endPointUrl, config);
      const res = response.data

      dispatch({
        type: GOT_CONDITION_LIST,
        payload: { res }
      });
    }
    catch (err) {
      console.log(err.message)
    }
  }
}

export const searchSubmit = () => {
  return async function (dispatch, getState) {

    dispatch({
      type: SEARCH_SUBMIT
    });

    const { searchCriteria } = getState()
    const cond = searchCriteria["cond"]
    const term = searchCriteria["term"]

    const phase = searchCriteria["phase"].filter(item => item.value === true).map(item => {
      return (item.value) ? "&phase=" + item.id : ""
    }).join('')

    const fund = searchCriteria["fund"].filter(item => item.value === true).map(item => {
      return (item.value) ? "&fund=" + item.id : ""
    }).join('')

    try {

      let res = [], i = 1;
      do {

        let postBody = {
          cond: cond,
          term: term,
          phase: phase,
          fund: fund,
          chunk: i
        }

        const endPointUrl = `${getBaseUrl()}csv`
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        }
        const response = await axios.post(endPointUrl, JSON.stringify(postBody), config);
        res = response.data

        dispatch({
          type: SEARCH_RECEIVED,
          payload: { res }
        });

        i++
      }
      while (res.length >= 100)

      //Udate the query url used to share
      let searchCriteriaUrlObject = {}
      for (let [key, value] of Object.entries(searchCriteria)) {

        if (key === "cond" || key === "term") {
          if (value !== null && value !== "") {
            searchCriteriaUrlObject[key] = value
          }
        }
        else {
          searchCriteriaUrlObject[key] = value.filter(item => item.value === true).map(item => parseInt(item.id))
        }
      }

      let criteriaTxt = encodeURIComponent(JSON.stringify(searchCriteriaUrlObject))
      let urlType = "searchCriteria"

      dispatch({
        type: UPDATE_URL_PARAMS,
        payload: { urlType, criteriaTxt }
      });

      //create the intial filter values
      const { allStudies } = getState()

      const names = allStudies.length > 0 ? Object.keys(allStudies[0]) : []

      const filteringCriteria = {}
      const filteringSelected = {}
      const filters = names.filter(
        name => name !== "id" &&
          /*name !== "title" &&
          name !== "studyId" &&
          name !== "outcomes" &&*/
          name !== "enrollment" &&
          name !== "primaryCompletionDate" &&
          name !== "results_first_submitted_date" &&
          name !== "study_first_submitted_date" &&
          name !== "duration" &&
          name !== "meta_prim_outcome" &&
          name !== "meta_time_frame" &&
          name !== "completionDate").map(name => {
            const all = allStudies.map(item => { return item[name] })
            if (all[0] instanceof Array) {
              const unique = [].concat(...all)
              filteringCriteria[name] = [...new Set(unique)]
              filteringSelected[name] = []
            }
            else {
              const unique = [...new Set(all)];
              if (name === "startDate") {
                const timeDomainStart = _.minBy(unique, function (d) {
                  return d;
                })
                const timeDomainEnd = _.maxBy(unique, function (d) {
                  return d;
                })

                filteringCriteria[name] = new Date(timeDomainStart).getFullYear()
                filteringSelected[name] = new Date(timeDomainStart).getFullYear()
                filteringCriteria["endDate"] = new Date(timeDomainEnd).getFullYear()
              }
              else {
                filteringCriteria[name] = unique
                filteringSelected[name] = []
              }
            }

            return {
              name
            }
          })

      dispatch({
        type: SET_INITIAL_FILTERS,
        payload: { filteringCriteria, filteringSelected }
      });

    }

    catch (err) {
      console.log(err.message)
      const message = err.message
      const type = "search_error"
      dispatch({
        type: API_ERROR,
        payload: { type, message }
      });
    }
  }
}

export const searchPhaseSelected = (id, type) => ({
  type: SEARCH_PHASE_SELECTED,
  payload: { id, type }
});

export const searchFundSelected = (id, type) => ({
  type: SEARCH_FUND_SELECTED,
  payload: { id, type }
});

export const setSearchCriteriaValue = (id, value) => ({
  type: SET_SEARCH_CRITERIA_VALUE,
  payload: { id, value }
})

export const toggleFilter = () => {
  return async function (dispatch) {
    dispatch({
      type: TOGGLE_FILTER
    });
  }
}

export const toggleToTypeFunc = (type) => {
  return async function (dispatch) {
    dispatch({
      type: TOGGLE_TO_TYPE,
      payload: { type }
    });
  }
}

export const updateFilter = (value, type) => {
  return async function (dispatch, getState) {

    let { filteringSelected, allStudies } = getState()
    let filteredStudies = allStudies

    filteringSelected[type] = value

    for (const element of Object.keys(filteringSelected)) {
      const selection = filteringSelected[element]

      if (element === "startDate") {

        filteredStudies = filteredStudies.filter(study => new Date(study[element]).getFullYear() >= selection)

      }
      else {
        if (selection.length > 0) {
          if (filteredStudies[0][element] instanceof Array) {
            filteredStudies = filteredStudies.filter(study => study[element].some(item => selection.includes(item)))
          }
          else {
            filteredStudies = filteredStudies.filter(study => selection.indexOf(study[element]) > -1)
          }
        }
      }
    }

    dispatch({
      type: UPDATE_FILTER,
      payload: { filteringSelected, filteredStudies }
    });

    //Udate the query url used to share
    let filteringCriteriaUrlObject = {}
    for (let [key, value] of Object.entries(filteringSelected)) {
      if (key === "startDate") {
        if (value !== null && value !== "") {
          filteringCriteriaUrlObject[key] = value
        }
      }
      else {
        if (value.length > 0) {
          filteringCriteriaUrlObject[key] = value
        }
      }
    }

    let criteriaTxt = encodeURIComponent(JSON.stringify(filteringCriteriaUrlObject))
    let urlType = "filteringCriteria"

    dispatch({
      type: UPDATE_URL_PARAMS,
      payload: { urlType, criteriaTxt }
    });
  }
}

export const resetFilter = () => {
  return async function (dispatch) {
    dispatch({
      type: RESET_FILTER
    });
  }
}

export const updateStateWithSearchCriteria = (searchTerms, filteringTerms) => {
  return async function (dispatch, getState) {

    dispatch({
      type: UPDATE_STATE_WITH_SEARCH_CRITERIA,
      payload: { searchTerms }
    });

    dispatch(searchSubmit()).then(() => {

      let { filteringSelected, allStudies } = getState()
      let filteredStudies = allStudies

      console.log(filteringTerms)
      for (const element of Object.keys(filteringTerms)) {
        filteringSelected[element] = filteringTerms[element]
        const selection = filteringSelected[element]

        if (element === "startDate" || element === "endDate") {
          filteredStudies = filteredStudies.filter(study => new Date(study[element]).getFullYear() >= selection)
        }

        else {
          if (selection.length > 0) {
            if (filteredStudies[0][element] instanceof Array) {
              filteredStudies = filteredStudies.filter(study => study[element].some(item => selection.includes(item)))
            }
            else {
              filteredStudies = filteredStudies.filter(study => selection.indexOf(study[element]) > -1)
            }
          }
        }
      }

      dispatch({
        type: UPDATE_FILTER,
        payload: { filteringSelected, filteredStudies }
      });

      let type = "GraphsList"
      dispatch({
        type: TOGGLE_TO_TYPE,
        payload: { type }
      });
    })
  }
}